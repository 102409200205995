import styled from 'styled-components'
import { standardContainerPadding } from '../../utils/constants'

export default styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  h2,
  h3 {
    text-align: right;
  }
  h2 {
    margin: 0;
  }
  margin-right; ${standardContainerPadding}px;
`
export const StyledEmoji = styled.span`
  role: 'img';
  font-size: 20px;
  margin-left: ${standardContainerPadding}px;
`
