import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';

import StyledCover, {
  CoverContainer,
  StyledCoverChildren
} from './Cover.styles'

const Cover = ({ children, imageUrl, withBackground = false }) => {
  const [showCover, setShowCover] = useState(false)
  const [close, setClose] = useState(false)
  const [showChildren, setShowChildren] = useState(false)
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => setShowCover(true), 0)
    if (showCover) setTimeout(() => setShowChildren(true), 2000)
  }, [showCover])

  const hideCover = () => {
    setTimeout(() => setClose(true), 0)
    setTimeout(() => setShowChildren(false), 2000)
    setTimeout(() =>  history.push("/home"), 2000)
  }
  return (
    <>
      <CoverContainer onClick={()=>hideCover()}>
        <StyledCover visible={showCover} close={close} url={imageUrl}>
          <StyledCoverChildren
            withBackground={withBackground}
            visible={showChildren}
            close={close}
            >
            {children}
          </StyledCoverChildren>
        </StyledCover>
      </CoverContainer>
    </>
  )
}

Cover.propTypes = {
  children: PropTypes.node,
  imageUrl: PropTypes.string,
  withBackground: PropTypes.bool
}
export default Cover
