import styled, { css } from 'styled-components'
import getTransparentHex from '../../utils/color'
import { h1Styles } from '../../styles/text.styles'

export default styled.div`
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${({ url }) => url});
  z-index: 100;
  opacity: 0;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      transition: 2s;
    `}
  ${({ close }) =>
    close &&
    css`
      opacity: 0;
      transition: 2s;
    `}
  ${h1Styles};
`

export const CoverContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.background};
`
export const StyledCoverChildren = styled.div`
  opacity: 0;
  z-index: 101;
  color: ${({ theme }) => theme.colors.primary.background};
  padding: 0 100px;
  border-radius: 5px;

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      transition: 2s;
      color: ${({ theme }) => theme.colors.text.inverted};
    `}

  ${({ close }) =>
    close &&
    css`
      opacity: 0;
      transition: 2s;
    `}

  ${({ withBackground, theme }) =>
    withBackground &&
    css`
      background-color: ${() =>
        getTransparentHex(theme.colors.primary.background, 0.15)};
    `}
`
