const moods = {
  default: {
    name: 'default'
  },
  cottageCore: {
    name: 'cottageCore'
  }
}
const themes = {
  default: {
    colors: {
      primary: {
        default: '#0067AC',
        background: '#cebbfa'
      },
      text: {
        standard: '#404040',
        inverted: '#faecdb',
        hover: '#F5B514'
      },
      actions: {
        success: '#4D7E20',
        succesPale: '',
        alert: '#F5B514',
        alertPale: '',
        error: '#dc1b09',
        errorPale: ''
      }
    }
  }
}

export { moods, themes }
