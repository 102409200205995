import { css } from 'styled-components'

export const h1Styles = css`
  h1 {
    font-family: Groovy;
    font-size: 150px;
    font-weight: normal;
    font-style: italic;
    @media (max-width: 800px) {
      font-size: 48px;
    }
  }
`
export const h2Styles = css`
  h2 {
    font-family: Art-Nuvo;
    font-size: 48px;
    font-weight: normal;
  }
`
export const h3Styles = css`
  h3 {
    font-family: Sozzani-Regular;
    font-size: 30px;
    font-weight: normal;
  }
`
export const h4Styles = css`
  h4 {
    font-family: Sozzani-Regular;
    font-size: 21px;
    font-weight: normal;
  }
`
export const h5Styles = css`
  h5 {
    font-family: 'Sozzani-Regular', serif;
    font-size: 18px;
    font-weight: normal;
  }
`
export const h6Styles = css`
  h6 {
    font-family: 'Prompt', sans-serif;
    text-transform: uppercase;
    font-size: 12px;
  }
`
export const linkStyles = css`
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text.standard};
  }
`
export const h3leftMargin = css`
  h3 {
    margin: 0 0 0 70px;
  }
`
