const getTransparentHex = (baseColor, alpha) => {
  let alphaAsHex = Math.round(255 * alpha)
    .toString(16)
    .toUpperCase()
  alphaAsHex = alphaAsHex.length === 1 ? `0${alphaAsHex}` : alphaAsHex
  const alphaHexColor = `${baseColor}${alphaAsHex}`
  return alphaHexColor
}

export default getTransparentHex
