import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { standardContainerPadding } from '../../utils/constants'

const HeaderHeight = 80

export default styled.div`
  height: ${HeaderHeight}px;
  padding: ${standardContainerPadding}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StyledLogo = styled.div`
  padding: ${standardContainerPadding}px;
`
export const StyledNavbar = styled.div`
  display: flex;
  flex-direction: row;
`
export const StyledMenuItem = styled(Link)`
  padding: ${standardContainerPadding}px;  
}
`
