import styled , { css } from 'styled-components'
import { h3leftMargin } from '../../ui/styles/text.styles'

export default styled.div`
  max-width: 1000px;
  ${h3leftMargin}
  opacity: 0;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      transition: 2s;
    `}
`

export const PlaylistContainer = styled.div`
width: 100%;
margin: 50px;
`