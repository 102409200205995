import styled from 'styled-components'
import { standardContainerPadding } from '../../utils/constants'

export default styled.div`
  justify-content: center;
  align-items: center;
  padding: ${standardContainerPadding}px;
  width: 100%;
  height: 100%;
  display: flex;
`
