import React from 'react'
import PropTypes from 'prop-types'
import StyledLBody from './Body.styles'

const Body = ({ children }) => {
  return <StyledLBody>{children}</StyledLBody>
}
Body.propTypes = {
  children: PropTypes.node.isRequired
}
export default Body
