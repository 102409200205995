import React from 'react'
import PropTypes from 'prop-types'
import Header from '../Header'
import Footer from '../Footer'
import Body from '../Body'
import StyledLayout from './Layout.styles'

const Layout = ({ children, image, hideHeader = false }) => {
  return (
    <StyledLayout url={image}>
      {!hideHeader && <Header />}
      <Body>{children}</Body>
      <Footer />
    </StyledLayout>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideHeader: PropTypes.bool
}
export default Layout
