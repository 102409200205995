import React from 'react'
import StyledHeader, {
  StyledLogo,
  StyledNavbar,
  StyledMenuItem
} from './Header.styles'
import headerLinks from '../../../constants/headerLinks'

const NavBar = () => {
  const navBarItems = headerLinks
  return (
    <StyledNavbar>
      {navBarItems.map(({ path, name }) => (
        <StyledMenuItem to={path} key={name}>
          <h6>{name}</h6>
        </StyledMenuItem>
      ))}
    </StyledNavbar>
  )
}
const Header = () => {
  return (
    <StyledHeader>
      <StyledMenuItem to="/">
        <h6>Kat Terek</h6>
      </StyledMenuItem>
      <NavBar />
    </StyledHeader>
  )
}

export default Header
