import React, { useState, useEffect } from 'react'
import Layout from '../../ui/components/Layout'
import Signature from '../../ui/components/Signature'
import StyledHomeContainer, { PlaylistContainer } from './Home.styles'




const Playlist = () =>  <PlaylistContainer><iframe title='Moon Baby Lullabies #1 - Leo 2022'
//style="border-radius:12px"
 src="https://open.spotify.com/embed/playlist/5FcJpPaQzlov3Qkn75lqU6?utm_source=generator" 
 width="80%" height="380" frameBorder="0" 
 //allowfullscreen="" 
 allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
 </PlaylistContainer>

const Home = () => {
  const [showCover, setShowCover] = useState(false)

  useEffect(() => {
    setTimeout(() => setShowCover(true), 0)
    // if (showCover) setTimeout(() => setShowChildren(true), 2000)
  }, [showCover])

  return (
    <Layout hideHeader={true} image="/assets/landscape/20.jpg">
      <StyledHomeContainer visible={showCover}>
        <h2>Moon Baby Lullabies #1 - Leo 2022</h2>
        <h4>
        It has been 3844 days since I locked the doors to the student radio station booth and, with a heavy heart, announced that I would no longer continue to broadcast my radio show.
        <br/><br/>
        With trembling fingers and heartburn in my throat I typed "[...] Hopefully, some time in the future I will come back to broadcast..." pressed enter and submitted the message to the depths of the internet. That electric beat cut through the wires, bit by bit, like arsenic travelling across my synapses. One by one, repeating from cell to cell, Chinese whispers, you will never do music again. Not in this life, if you want to stay alive. There is only so much pain that a sensitive heart can bear.
        <br/><br/>
        It took ten years, six months and ten days. Ashes to ashes, dust to dust. I walked through fire so many times. I had to fall, I had to fly. The world had dropped dead and as I opened up my eyes everything was born again.
        <br/><br/>
        It took me some time to understand this. Many years to understand how important the right timing is for the right move. You can do everything right in the wrong time. Seeds of unlimited unconditional love in badlands will never yield a single flower.
        <br/><br/>
        I owe myself some kindness. It was all premature. I was just a baby, looking for love. I had moved to another country all on my own. My natural poetic fluency and quick wit were lost to the lack of words and inability to fit in. I thought I could make friends through sharing what I loved the most but my music didn't fit into the cool kids club either. I still remember following the sound of a thick juicy beat that drew me to the booth of Subcity radio. Other kids were drinking Buckfast that night, when I was moving old tv sets around, helping to set up my very first club night. 
        <br/><br/>
        I didn't really have friends. I tried my best to be a mystery. But in reality I was just an aloof lonely mess. So for every last minute email I received from the technical team, I showed up. Soundcheck for Twilight Sad, check. Set up for Scrobious Pip, check. Opening up a broken 6-foot long mixing desk to rewire it, check. Building a portal to another dimension, to teleport people from BST to DST on All Hallows Eve, check. Walking alone at 3am through the university campus every fortnight to broadcast night tunes to the world that no one listened to, check. Gently wrapping cables in circles, so as not to destroy the coils, check. Burning my fingers with a soldering gun, to fix the antique headphones. Trying to match the soft beats on the decks. Sleepless nights. Hours of research. Midnight coffee and Kafka. That was all I had. I was all alone. Before realising it, I had started sinking into that world. Loneliness.
        <br/><br/>
        I was ignoring that temporary feeling immersing myself inside a world of words. A book of insanity. A book of nursery rhymes. A book of love and hate. A book of lies.
        <br/><br/>
        I still had no friends. Just some people my flatmate knew, who had left their belongings at our place for the summer time. Piles of books that filled every corner. They lured me, whispering ‘Take me. Read me. Rip me apart. I’ll be yours and you’ll be mine.’ Palahniuk, Kundera, Allende, Bukowski, Ginsberg, Herbert, Kerouac, Freud, Zweig, Plath and Rand. Everything I was hungry to read or loved to read again and again. Good books corrupted my mind.
        <br/><br/>
        After what had felt like a hundred years of solitude, I was ready to let go. To keep the melodies to myself. To heal. To let go of the idea that there is a single soul alike out there. To harness the strength from that solitude and learn how it feels like to be loved. Truly, madly, deeply fall in love with myself.
        <br/><br/>
        From that moment, as the years passed, my inner light grew stronger. Like a newborn star, it grew bigger, brighter, warmer, keeping me warm through the winters of my life. I swear it must have been this light that brought you all to me like moths to a flame. Now that you're here, dancing like butterflies of the night around the dim lightbulb of my bedroom, slide on your headphones on and dive into my world of sounds, music that I have always wanted to share with you.
        <br/><br/>
        Let’s start small. Take baby steps. You are not ready for more yet and quite frankly neither am I. I will start where I finished to maybe one day finish where I started. Thank heavens for the return of Saturn and its cycles that provide us the opportunity to start all over again.
        <br/><br/>
        The 28th of July. A new Moon in Leo. A new year in my life. A new beginning for something I had left behind.
        <br/><br/>
        I dedicate this to my niece Julia, pulled out of her mama's womb by the strength of the full moon and to all the other Moon Babies out there who long to be swaddled with love and midnight melodies.
        </h4> 
        <Signature />
        <Playlist />
      </StyledHomeContainer>

    </Layout>
  )
}

export default Home
