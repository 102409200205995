import styled from 'styled-components'
import {
  h1Styles,
  h2Styles,
  h3Styles,
  h4Styles,
  h5Styles,
  h6Styles,
  linkStyles
} from '../../styles/text.styles'

export default styled.div`
  min-height: 100vh;
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  * {
    box-sizing: border-box;
  }
  background-image: url(${({ url }) => url});

  // background-color: ${({ theme }) => theme.colors.primary.background};
  color: ${({ theme }) => theme.colors.text.standard};

  ${h1Styles};
  ${h2Styles};
  ${h3Styles};
  ${h4Styles};
  ${h5Styles};
  ${h6Styles};
  ${linkStyles};
`
