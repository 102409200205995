import styled from 'styled-components'
import { standardContainerPadding } from '../../utils/constants'

export default styled.div`
  display: flex;
  flex: 0 1 70px;
  padding: ${standardContainerPadding}px;
  position: relative;
  flex-direction: row;
  align-content: stretch;
  margin-top: auto;
`
