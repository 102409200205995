const headerLinks = [
  {
    name: 'About',
    path: '/about'
  },
  {
    name: 'INFO',
    path: '/info'
  },
  {
    name: 'Contact',
    path: '/contact'
  },
  {
    name: 'Volunteering',
    path: '/volunteer'
  }
]

export default headerLinks
