import React from 'react'
import Cover from '../../ui/components/Cover'
import StyledEntry from './Front.styles.js'


const Home = () => {
  return (
    <Cover imageUrl="/assets/landscape/DECEMBER_28.jpg">
      <StyledEntry>
      <h1>Moon Baby Lullabies</h1>
      </StyledEntry>
    </Cover>
  )
}

export default Home