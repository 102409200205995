/* eslint-disable import/prefer-default-export */

const ROUTES = {
  ROOT: '/',
  ABOUT: '/about',
  INFO: '/info',
  HOME: '/home',
  CONTACT: '/contact',
  VOLUNTEER: '/volunteer'
}

export default ROUTES
