import React from 'react'
import StyledSignature, { StyledEmoji } from './Signature.styles'

const Signature = () => {
  return (
    <StyledSignature>
      <div>
        <h3>lots of love</h3>
        <h2>
          Kat
        </h2>
      </div>
    </StyledSignature>
  )
}
export default Signature
