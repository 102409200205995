import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import MoodProvider from '../ui/context'
import { Home, Front } from '../routes'
import ROUTES from '../constants/routes'
import 'normalize.css'

const App = () => {
  return (
    <MoodProvider>
      <BrowserRouter>
        <Switch>
          <Route component={Front} exact path={ROUTES.ROOT} />
          <Route component={Home} exact path={ROUTES.HOME} />
        </Switch>
      </BrowserRouter>
    </MoodProvider>
  )
}

export default App
