import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import MoodContext from './MoodContext'
import { moods, themes } from '../../constants/themes'

const MoodProvider = ({ children, mood = 'default' }) => {
  return (
    <MoodContext.Provider value={moods[mood]}>
      <ThemeProvider theme={themes[mood]}>{children}</ThemeProvider>
    </MoodContext.Provider>
  )
}

MoodProvider.propTypes = {
  children: PropTypes.node.isRequired,
  mood: PropTypes.oneOf(['default'])
}
export default MoodProvider
